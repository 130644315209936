import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import Breadcrumbs from "../../components/Breadcrumbs"

import "./Pages.css"

const TermosDeServico = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Termos de Serviço"
        canonical={`${process.env.GATSBY_HOST_URL}/pt/termos-de-servico`}
        isAlternate={true}
        alternateUrl={`${process.env.GATSBY_HOST_URL}/en/terms-of-service`}
      />

      <div className="topics">
        <div className="main-content" style={{ marginTop: 117 }}>
          <Breadcrumbs
            currentPage="Termos de Serviço"
            style={{ marginTop: 0, marginBottom: 30 }}
          />

          <div className="topics-header">
            <h1>Termos de Serviço</h1>
          </div>

          <div className="content-static-pages">
            <strong>1. ACEITAÇÃO</strong>

            <p>
              Este é um contrato firmado entre você, de agora em diante
              denominado como usuário, e a{" "}
              <b>Taha Brasil Tecnologia e Marketing LTDA</b>, inscrita no CNPJ
              nº 35.950.269/0001-13, com endereço a Rua Marechal Floriano nº
              654, Sala 607, Centro, CEP 35010-140, Governador Valadares – MG.
              Este “Termo de Uso de Aplicativo” rege o uso de todos os
              aplicativos disponibilizados gratuitamente pela Taha Brasil
              Tecnologia e Marketing LTDA, seja para dispositivos móveis
              (Android, IOS, Windows Mobile), servidores, computadores pessoais
              (desktops) ou serviços web. Se você não concordar com estes termos
              não use este aplicativo. Você reconhece ainda que analisou e
              aceitou as condições de uso. Recomendamos a leitura atenta, pois,
              o uso deste aplicativo significa que você aceitou todos os termos
              e concorda em cumpri-los. Se você, usuário, for menor de idade ou
              declarado incapaz em quaisquer aspectos, precisará da permissão de
              seus pais ou responsáveis, conforme disposições do Código Civil,
              os quais também deverão concordar com estes mesmos termos e
              condições.
            </p>

            <strong>2. LICENÇA LIMITADA</strong>

            <p>
              Você recebeu uma licença limitada, não transferível, não
              exclusiva, livre de royalties e revogável para baixar, instalar,
              executar e utilizar este aplicativo “NIZAM” em seu dispositivo.
              Você reconhece e concorda que a Taha Brasil Tecnologia e Marketing
              LTDA concede ao usuário uma licença exclusiva para uso e desta
              forma não lhe transfere os direitos sobre o produto. O aplicativo
              deverá ser utilizado apenas por você, usuário. A venda,
              transferência, modificação, engenharia reversa ou distribuição bem
              como a cópia de textos, imagens ou quaisquer partes nele contido é
              expressamente proibida.
            </p>

            <strong>3. ALTERAÇÕES, MODIFICAÇÕES E RESCISÃO</strong>

            <p>
              A Taha Brasil Tecnologia e Marketing LTDA, reserva-se no direito
              de, a qualquer tempo, modificar estes termos seja incluindo,
              removendo ou alterando quaisquer de suas cláusulas. Tais
              modificações terão efeito imediato. Após publicadas tais
              alterações, ao continuar com o uso do aplicativo, você terá
              aceitado e concordado em cumprir os termos modificados. A Taha
              Brasil Tecnologia e Marketing LTDA pode, de tempos em tempos,
              modificar ou descontinuar (temporária ou permanentemente) a
              distribuição ou a atualização deste aplicativo. A Taha Brasil
              Tecnologia e Marketing LTDA não é obrigada a fornecer nenhum
              serviço de suporte para este aplicativo. O usuário não poderá
              responsabilizar a Taha Brasil Tecnologia e Marketing LTDA seus
              diretores, executivos, funcionários, afiliados, agentes,
              contratados ou licenciadores por quaisquer modificações,
              suspensões ou descontinuidade do aplicativo.
            </p>

            <strong>CONSENTIMENTO PARA COLETA E USO DE DADOS</strong>

            <p>
              Você concorda que a Taha Brasil Tecnologia e Marketing LTDA pode
              coletar e usar dados técnicos de seu dispositivo tais como
              especificações, configurações, versões de sistema operacional,
              tipo de conexão à internet e afins, tudo conforme a legislação
              vigente e suas posteriores alterações.
            </p>

            <strong>
              ISENÇÃO DE GARANTIAS E LIMITAÇÕES DE RESPONSABILIDADE
            </strong>

            <p>
              Este aplicativo estará em contínuo desenvolvimento e pode conter
              erros e, por isso, o uso é fornecido "no estado em que se
              encontra" e sob risco do usuário final. Na extensão máxima
              permitida pela legislação aplicável a Taha Brasil Tecnologia e
              Marketing LTDA e seus fornecedores isentam-se de quaisquer
              garantias e condições expressas ou implícitas incluindo, sem
              limitação, garantias de comercialização, adequação a um propósito
              específico, titularidade e não violação no que diz respeito ao
              aplicativo e qualquer um de seus componentes ou ainda à prestação
              ou não de serviços de suporte. A Taha Brasil Tecnologia e
              Marketing LTDA não garante que a operação deste aplicativo seja
              contínua e sem defeitos.
            </p>

            <p>
              Exceto pelo estabelecido neste documento, não há outras garantias,
              condições ou promessas aos aplicativos, expressas ou implícitas, e
              todas essas garantias, condições e promessas podem ser excluídas
              de acordo com o que é permitido por lei sem prejuízo à Taha Brasil
              Tecnologia e Marketing LTDA e seus colaboradores.
            </p>

            <p>
              I. A Taha Brasil Tecnologia e Marketing LTDA não garante, declara
              ou assegura que o uso deste aplicativo será ininterrupto ou livre
              de erros e você concorda que a Taha Brasil Tecnologia e Marketing
              LTDA poderá remover por períodos indefinidos ou cancelar este
              aplicativo a qualquer momento sem que você seja avisado.
            </p>

            <p>
              II. A Taha Brasil Tecnologia e Marketing LTDA não garante, declara
              nem assegura que este aplicativo esteja livre de perda,
              interrupção, ataque, vírus, interferência, pirataria ou outra
              invasão de segurança e isenta-se de qualquer responsabilidade em
              relação à essas questões. Você é responsável pelo backup do seu
              próprio dispositivo.
            </p>

            <p>
              III. Em hipótese alguma a Taha Brasil Tecnologia e Marketing LTDA,
              bem como seus diretores, executivos, funcionários, afiliadas,
              agentes, contratados ou licenciadores responsabilizar-se-ão por
              perdas ou danos causados pelo uso do aplicativo.
            </p>

            <p>
              IV. Os conteúdos transmitidos pelos aplicativos tais como a
              artigos, imagens, textos, fontes, traduções e afins são de
              responsabilidade de seus idealizadores. A Taha Brasil Tecnologia e
              Marketing LTDA isenta-se de qualquer responsabilidade direta ou
              indireta sobre estes conteúdos e o acesso é facultativo ao
              usuário.
            </p>

            <p>Governador Valadares, 11 de Agosto de 2020.</p>

            <strong style={{ display: "block", textAlign: "center" }}>
              A Taha Brasil Tecnologia e Marketing LTDA
            </strong>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TermosDeServico
